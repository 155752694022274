import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import {
    createBrowserRouter,
    RouterProvider,
    Outlet,
} from "react-router-dom";
import Playground from "./routes/Playground/Playground";
import Create from "./routes/Create/create"
import GlossaryGPT from "./routes/glossarygpt/create_page";
import Navbar from "./components/Navbar";
import MarkdownDisplay from "./routes/Feedback/feedback";
import "./App.css";
import "./index.css";
import { UserProvider } from './UserContext';
import { FaBars } from "react-icons/fa";
import MainContent from './components/MainContent'
import LoginPage from './routes/Auth/LoginPage'
import AuthLayout from './components/AuthLayout';
import Repository from "./routes/Repository/repository";
import Console from "./routes/console/console";

const AppLayout = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isHidden, setIsHidden] = useState(false);

    const handleResize = () => {
        if (window.innerWidth <= 600) {
            setIsHidden(true); // Hide the navbar if viewport is very small
        } else {
            setIsHidden(false); // Show the navbar if viewport is larger
            setIsCollapsed(window.innerWidth <= 1000); // Collapse the navbar if viewport is less than or equal to 1000px
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize(); // Check initial window size
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const toggleSidebar = () => {
        if (window.innerWidth <= 600) {
            // If navbar is hidden and viewport is very small
            setIsHidden(!isHidden); // Toggle navbar visibility
            setIsCollapsed(true); // Ensure navbar is collapsed when shown
        } else {
            // For larger viewports, just toggle collapsed state
            setIsCollapsed(!isCollapsed);
        }
    };

    return (
        <div className="flex h-screen bg-gray-100">
            {!isHidden && <Navbar isCollapsed={isCollapsed} />} {/* Conditionally render the Navbar */}
            <div className="flex-1 flex flex-col">
                <div className="flex justify-between items-center p-4 shadow-sm bg-gray-100">
                    <FaBars 
                        className="text-xl sm:text-2xl cursor-pointer mr-6 sm:mr-10"
                        onClick={toggleSidebar} 
                    />
                   <MainContent className="text-sm sm:text-base" />
                </div>
                <div className="p-4">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

const router = createBrowserRouter([
    {
        element: <AppLayout />,
        children: [
            {
                path: "/glossarygpt",
                element: <GlossaryGPT />,
            },
            {
                path: "/",
                element: <Create />,
            },
            {
                path: "playground",
                element: <Playground />,
            },
            {
                path: "feedback",
                element: <MarkdownDisplay />,
            },
            {
                path: "repository",
                element: <Repository />,
            },
            {
                path: "console",
                element: <Console />,
            },
        ],
    },
    {
        element: <AuthLayout />,  // Use AuthLayout for auth-related routes
        children: [
            {
                path: "login",
                element: <LoginPage />,
            },
            // Add other auth-related routes here, like SignUpPage
        ],
    },
]);

const App = () => {
    const [userId, setUserId] = useState(null);

    return (
        <UserProvider value={{ userId, setUserId }}>
            <RouterProvider router={router} />
        </UserProvider>
    );
};

createRoot(document.getElementById("root")).render(<App />);
