import React, { useEffect, useRef, useState } from "react";
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";

const Recording = ({ setInterimTranscription, setFinalTranscription }) => {
    const [isListening, setIsListening] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [startTime, setStartTime] = useState(null); // Store the start time
    const [transcriptionDuration, setTranscriptionDuration] = useState(0); // Store transcription duration in seconds
    const recognizerRef = useRef(null);
    const timeoutRef = useRef(null);

    // Check if the user is logged in (i.e., if the token exists in localStorage)
    useEffect(() => {
        const token = localStorage.getItem('token');
        setIsLoggedIn(!!token); // Set isLoggedIn to true if token exists, false otherwise
    }, []);

    // Initialize the Azure Speech SDK recognizer
    useEffect(() => {
        const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(
            process.env.REACT_APP_AZURE_SPEECH_KEY,
            process.env.REACT_APP_AZURE_REGION
        );
        speechConfig.speechRecognitionLanguage = 'en-US'; // Always transcribe in English

        const audioConfig = SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
        recognizerRef.current = new SpeechSDK.SpeechRecognizer(speechConfig, audioConfig);

        let finalText = '';

        recognizerRef.current.recognizing = (s, e) => {
            setInterimTranscription(e.result.text);
        };

        recognizerRef.current.recognized = (s, e) => {
            if (e.result.reason === SpeechSDK.ResultReason.RecognizedSpeech) {
                finalText += e.result.text + ' '; // Accumulate recognized speech
                setFinalTranscription(finalText.trim()); // Update the final transcription progressively
            }
        };

        recognizerRef.current.canceled = (s, e) => {
            console.error(`Recognition canceled: ${e.reason}`);
            if (e.reason === SpeechSDK.CancellationReason.Error) {
                console.error(`Error details: ${e.errorDetails}`);
            }
            setIsListening(false);
            clearTimeout(timeoutRef.current); // Clear the timeout if recognition is canceled
            calculateTranscriptionDuration(); // Calculate duration on cancel
        };

        recognizerRef.current.sessionStopped = (s, e) => {
            console.log("Session stopped.");
            setInterimTranscription(''); // Clear interim transcription
            setIsListening(false);
            clearTimeout(timeoutRef.current); // Clear the timeout when session stops
            calculateTranscriptionDuration(); // Calculate duration on stop
        };

        return () => {
            recognizerRef.current.close();
            recognizerRef.current = null;
        };
    }, [setInterimTranscription, setFinalTranscription]);

    const handleListen = () => {
        if (!isLoggedIn) {
            alert("Please log in to start transcription."); // Show alert if not logged in
            return;
        }
        
        if (!isListening) {
            setIsListening(true);
            setStartTime(Date.now()); // Record start time
            // Automatically stop transcription after 4 minutes (240000 milliseconds)
            timeoutRef.current = setTimeout(() => {
                recognizerRef.current.stopContinuousRecognitionAsync();
            }, 240000);
        } else {
            setIsListening(false);
            clearTimeout(timeoutRef.current); // Clear the timeout if manually stopped
            calculateTranscriptionDuration(); // Calculate duration on manual stop
        }
    };

    const calculateTranscriptionDuration = async () => {
        if (startTime) {
            const durationInSeconds = Math.floor((Date.now() - startTime) / 1000);
            setTranscriptionDuration(durationInSeconds);
            console.log(`Transcription duration: ${durationInSeconds} seconds`); 
    
            try {
                const response = await fetch("https://koducks.com/api/transcription_time", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({ time: durationInSeconds })
                });
    
                if (!response.ok) {
                    throw new Error(`Error: ${response.statusText}`);
                }
    
                const data = await response.json();
                console.log("API response:", data);
            } catch (error) {
                console.error("Failed to update transcription time:", error);
            }
        }
    };

    useEffect(() => {
        if (isListening) {
            recognizerRef.current.startContinuousRecognitionAsync();
        } else {
            recognizerRef.current.stopContinuousRecognitionAsync();
        }
    }, [isListening]);

    return (
        <div className="flex flex-col items-center space-x-4 mt-4">
            <button
                onClick={handleListen}
                className={`listen-button bg-black text-white rounded-full px-3 py-1 border-2 border-gray-500 focus:border-gray-500 transition duration-300 hover:bg-gray-700 focus:outline-none focus:ring-0 ${isListening ? 'bg-gray-500' : 'bg-black'}`}
            >
                {isListening ? 'Stop' : 'Start'}
            </button>
            {isListening && (
                <p>Listening...</p>
            )}
            {!isListening && transcriptionDuration > 0 && (
                <p>{transcriptionDuration} seconds</p>
            )}
        </div>
    );
};

export default Recording;