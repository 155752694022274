import React from "react";
import { NavLink } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";

const Navbar = ({ isCollapsed }) => {
    const SidebarData = [
        {
            title: "Create Speech",
            path: "/",
            icon: <AiIcons.AiOutlinePlus />,
            cName: "nav-text",
        },
        {
            title: "Repository",
            path: "/repository",
            icon: <IoIcons.IoMdCloudOutline />,
            cName: "nav-text",
        },
        {
            title: "Console",
            path: "/console",
            icon: <FaIcons.FaRegDotCircle />,
            cName: "nav-text",
        },
        {
            title: "GlossaryGPT",
            path: "/glossarygpt",
            icon: <AiIcons.AiOutlineArrowRight />,
            cName: "nav-text",
        }
    ];

    return (
        <div
            className={`bg-neutral-200 h-full shadow-lg transition-all duration-300 ${isCollapsed ? 'w-16' : 'w-80'}`}
        >
            <nav className="flex flex-col space-y-4 p-2 mt-2">
                {SidebarData.map((item, index) => (
                    <NavLink
                        key={index}
                        to={item.path}
                        className={({ isActive }) =>
                            isActive
                                ? `flex items-center ${isCollapsed ? 'justify-center' : 'justify-start'} text-white bg-gray-700 py-4 px-4 rounded-xl`
                                : `flex items-center ${isCollapsed ? 'justify-center' : 'justify-start'} text-gray-800 py-4 px-4 rounded-lg hover:bg-gray-300 hover:text-gray-900 font-bold` 
                        }
                    >
                        <span className={`text-sm ${isCollapsed ? '' : 'mr-4'}`}>{item.icon}</span>
                        {!isCollapsed && <span className="text-sm font-bold font-sans">{item.title}</span>}
                    </NavLink>
                ))}
            </nav>
        </div>
    );
};

export default Navbar;
