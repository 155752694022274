import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

const MarkdownDisplay = () => {
  const [toc, setToc] = useState([]);

  // Retrieve feedback from local storage if available
  const storedFeedback = localStorage.getItem('formattedFeedback');
  
  // Default markdown content
  let markdownContent = `
  # Feedback:

  This is where feedback will appear.
  `;

  try {
    // Attempt to parse the stored feedback, fallback to default content
    const parsedFeedback = storedFeedback ? JSON.parse(storedFeedback) : null;
    markdownContent = parsedFeedback || markdownContent;
    
    // Replace escaped newline characters with actual newlines
    markdownContent = markdownContent.replace(/\\n/g, '\n');
  } catch (error) {
    console.error("Failed to parse stored feedback:", error);
  }

  useEffect(() => {
    // Generate table of contents with only h1 and h2 headings
    const headings = markdownContent.match(/^#{1,2} .+/gm);
    if (headings) {
      const tocItems = headings.map((heading) => {
        const level = heading.split(' ')[0].length;
        const title = heading.replace(/^#{1,2} /, '');
        const id = title.toLowerCase().replace(/\s+/g, '-');
        return { level, title, id };
      });
      setToc(tocItems);
    }
  }, [markdownContent]);

  const downloadMarkdown = () => {
    const blob = new Blob([markdownContent], { type: 'text/markdown' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'feedback.md';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="relative w-full max-w-5xl mx-auto my-4 rounded-lg bg-gray-100">
      <div className="flex">
        {/* New vertically flexed container */}
        <div className="w-1/4 p-4 bg-gray-100 rounded-lg flex flex-col hidden sm:block">
          <button
            onClick={downloadMarkdown}
            className="bg-black text-white text-xs py-1 px-2 mb-8 rounded hover:bg-gray-600 self-start"
          >
            Download
          </button>
          <h3 className="text-lg font-semibold mb-4">Contents</h3>
          <ul>
            {toc.map((item, index) => (
              <li key={index} className={`ml-${item.level * 2}`}>
                <a href={`#${item.id}`} className="text-gray-800 hover:underline">
                  {item.title}
                </a>
              </li>
            ))}
          </ul>
          
        </div>
        <div className="w-full sm:w-3/4 h-[680px] overflow-y-auto p-16 text-base bg-gray-100 text-gray-800 rounded-lg">
          <ReactMarkdown
            components={{
              h1: ({ node, ...props }) => (
                <h1 id={props.children[0].toLowerCase().replace(/\s+/g, '-')} className="text-xl font-bold mt-6 mb-4 text-gray-900" {...props} />
              ),
              h2: ({ node, ...props }) => (
                <h2 id={props.children[0].toLowerCase().replace(/\s+/g, '-')} className="text-lg font-semibold mt-4 mb-2 text-gray-800" {...props} />
              ),
              h3: ({ node, ...props }) => (
                <h3 id={props.children[0].toLowerCase().replace(/\s+/g, '-')} className="text-base font-medium mt-2 mb-2 text-gray-700" {...props} />
              ),
              p: ({ node, ...props }) => <p className="mb-4 text-gray-800" {...props} />,
              blockquote: ({ node, ...props }) => (
                <blockquote className="border-l-4 border-gray-300 pl-4 italic text-gray-600 mb-4" {...props} />
              ),
              ul: ({ node, ...props }) => (
                <ul className="list-disc list-inside mb-4 text-gray-800" {...props} />
              ),
              ol: ({ node, ...props }) => (
                <ol className="list-decimal list-inside mb-4 text-gray-800" {...props} />
              ),
              li: ({ node, ...props }) => <li className="mb-2" {...props} />,
              code: ({ node, inline, className, children, ...props }) => (
                inline ? (
                  <code className="bg-gray-200 rounded px-2 py-1 text-red-500" {...props}>
                    {children}
                  </code>
                ) : (
                  <pre className="bg-gray-800 text-gray-200 p-4 rounded mb-4 overflow-x-auto">
                    <code {...props}>{children}</code>
                  </pre>
                )
              ),
              a: ({ node, ...props }) => (
                <a className="text-blue-500 hover:underline" {...props} />
              ),
              // Add more component overrides as needed
            }}
          >
            {markdownContent}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

export default MarkdownDisplay;
