import React, { useState, useEffect, useRef } from 'react';
import './playground.css';
import Recording from './Recording'
import AudioPlayer from './audio_player';
import Transcription from './Feedback';
import SpeechSelect from './search_speech';


const SpeechManagement = () => {
    const [audioUrl, setAudioUrl] = useState(null);
    const [interimTranscription, setInterimTranscription] = useState('');
    const [finalTranscription, setFinalTranscription] = useState('');
    const [speech, setSpeech] = useState('');
    const [transcription, setTranscription] = useState('');
    const [terms, setTerms] = useState('');
    const [text, setText] = useState('');
    const [structure, setStructure] = useState('');
    const [showTerms, setShowTerms] = useState(false); // State to toggle terms visibility

    useEffect(() => {
        // Retrieve the selected audio URL from localStorage
        const storedAudioUrl = localStorage.getItem('selectedSpeechUrl');
        if (storedAudioUrl) {
            setAudioUrl(storedAudioUrl);
        }
    }, []);

    const handleToggleTerms = () => {
        setShowTerms(prevState => !prevState); // Toggle visibility
    };

    const concatenateFields = (terms, structure, text) => {
        let formatted_terms = `术语提示 \n\n`;
        formatted_terms += `${terms}\n\n`;
        let formatted_structure = `原文结构 \n\n`;
        formatted_structure += `${structure}\n\n`;
        let formatted_text = `演讲全文 \n\n`;
        formatted_text += `${text}\n\n`;
        return `${formatted_terms || ''} ${formatted_structure || ''} ${formatted_text || ''}`.trim();
    };



    return (
        <div className="ImproveHere">
            <div className="expandedContainer">
                <AudioPlayer audioUrl={audioUrl} />
                {/* Container for SpeechSelect and Recording */}
                <div className="flex items-center space-x-4 w-2/4 sm:w-3/4 max-w-md mx-auto mt-6">
                <SpeechSelect 
                    setAudioUrl={setAudioUrl} 
                    setSpeech={setSpeech} 
                    setTerms={setTerms} 
                    setStructure={setStructure}
                    setText={setText} 
                />
                <Recording
                    setInterimTranscription={setInterimTranscription}
                    setFinalTranscription={setFinalTranscription}
                />
            </div>

                {/* Button to toggle between showing terms and transcription */}
                <button
                    onClick={handleToggleTerms}
                    className="mt-4 p-2 bg-transparent text-black hover:bg-gray-100 rounded"
                    title="Click to see terms"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className={`w-6 h-6 transform ${showTerms ? 'rotate-180' : ''} transition-transform duration-300`}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                    >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
                    </svg>
                </button>

                {/* Conditionally show terms or transcription */}
                {showTerms ? (
                    terms && (
                        <textarea
                            className="w-full sm:w-1/2 max-w-2xl p-8 mt-4 rounded-md bg-gray-100 text-left mx-auto shadow-lg hover:shadow-xl focus:shadow-xl scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200"
                            value={concatenateFields(terms, structure, text)}
                            readOnly
                            rows={12} // Display long list of terms
                        />
                    )
                ) : (
                    <Transcription
                        setTranscription={setTranscription}
                        interimTranscription={interimTranscription}
                        finalTranscription={finalTranscription}
                        speech={speech}
                    />
                )}
            </div>
        </div>
    );
};



export default SpeechManagement;