import React, { useState, useEffect, useRef } from 'react';
import CreateArticle from './create_article'
import ArticleList from './articles'


const Create = () => {
    return (
        <div className="ImproveHere">
            <div className="createArticleContainer">
            <div className="text-xl md:text-2xl lg:text-3xl font-bold text-gray-800 mb-2 md:mb-4 text-center">
                Create a New Speech
            </div>
                <CreateArticle />
                <div className="text-sm text-gray-600 text-center mt-2 md:mt-4">
                    Refresh to see more speech recommendations
                </div>
                <ArticleList />
            </div>
        </div>
    );
};


export default Create;