import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Box, IconButton, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send'; 
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

const TranscriptionPanel = ({ setTranscription, finalTranscription, interimTranscription, speech }) => {
    const [userInput, setUserInput] = useState('');
    const [feedback, setFeedback] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleTextChange = (event) => {
        const textValue = event.target.value;
        const trimmedValue = textValue.replace(finalTranscription + interimTranscription, '');
        setUserInput(trimmedValue);
    };

    const handleFeedbackClick = async () => {
        setLoading(true); // Set loading to true when the button is clicked
        const transcription = finalTranscription + interimTranscription + userInput;

        let speechId = parseInt(speech, 10);

        if (isNaN(speechId)) {
            alert("Please select a speech before submitting for feedback.");
            setLoading(false); // Reset loading state on error
            return;
        }

        const token = localStorage.getItem('token');
    
        if (!token) {
            alert("You must be logged in to receive feedback.");
            setLoading(false); // Reset loading state if no token is found
            return;
        }

        const wordCount = transcription.trim().split(/\s+/).length;
        if (wordCount <= 100) {
            alert('The transcription must be long enough to receive feedback.');
            setLoading(false); // Reset loading state if transcription is too short
            return;
        }

        try {
            const response = await axios.post('https://koducks.com/api/get_feedback', {
                speech_id: speechId,
                translation: transcription,
            }, {
                headers: {
                    "Authorization": `Bearer ${token}`, // Include the JWT token in the Authorization header
                    "Content-Type": "application/json",
                }
            });

            if (response.status === 200) {
                const feedbackObject = response.data.feedback;
                const formattedFeedback = formatFeedback(feedbackObject);
                localStorage.setItem('formattedFeedback', JSON.stringify(formattedFeedback));
                navigate('/feedback');
                alert('Your feedback is ready. Click on OK to view!');
            } else {
                alert('Failed to receive feedback');
            }
        } catch (error) {
            console.error('An error occurred while processing feedback:', error);
            alert('An error occurred while processing feedback');
        } finally {
            setLoading(false); // Reset loading state after response is received
        }
    };

    const formatHeadings = (content) => {
  
        const lines = content.split('\n');
        let firstHeadingFound = false;
    
        const formattedLines = lines.map(line => {
          let modifiedLine = line.replace(/\*/g, '');
    
        if (modifiedLine.startsWith('#')) {
            if (firstHeadingFound) {
              modifiedLine = `##${modifiedLine}`;
            } else {
              firstHeadingFound = true;
            }
        }
        return modifiedLine;
      });
    
        return formattedLines.join('\n');
      };
      
    
    const formatFeedback = (feedbackObject) => {
        let formatted = '';
    
        const missing_info = feedbackObject.info;
        const formattedMissingInfo = formatHeadings(missing_info);
        const expressions = feedbackObject.expression;
        const formattedExpressions = formatHeadings(expressions);
        const terms = feedbackObject.term;
        const formattedTerms = formatHeadings(terms);
        const speech = feedbackObject.speech.replace('演讲原文：', '演讲原文');
        const questions = feedbackObject.questions;
    
        formatted += `${speech}\n\n`;
        formatted += `\n\n`;
        formatted += `\n\n`;
        formatted += `${formattedMissingInfo}\n\n`;
        formatted += `\n\n`;
        formatted += `\n\n`;
        formatted += `${formattedExpressions}\n\n`;
        formatted += `\n\n`;
        formatted += `# 话题词汇积累\n\n`;
        formatted += `${formattedTerms}\n\n`;
        formatted += `\n\n`;
        formatted += `# Follow-up questions\n\n`;
        formatted += `${questions}\n\n`;
    
        return formatted;
    };

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                maxWidth: { xs: '90%', sm: '500px', md: '700px' },
                margin: '10px auto',
                height: '340px',
                padding: '25px',
                border: '1px solid #808080',
                borderRadius: '10px',
                backgroundColor: '#f9f9f9',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
        >
            <TextField
                value={finalTranscription + interimTranscription + userInput}
                onChange={handleTextChange}
                placeholder="Transcription will appear here..."
                multiline
                minRows={11}
                maxRows={11}
                fullWidth
                variant="outlined"
                sx={{
                    '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        fontSize: '16px',
                        backgroundColor: '#fff',
                        color: '#333',
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#808080',
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: '#808080',
                        },
                        height: '100%',
                        overflowY: 'auto',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#ccc',
                    },
                    '& .MuiInputBase-input::placeholder': {
                        color: '#aaa',
                        fontSize: '14px',
                    },
                }}
            />
            <Tooltip title="Submit to get feedback!" placement="right"> {/* Tooltip with hover text */}
                <IconButton
                    onClick={handleFeedbackClick}
                    sx={{
                        position: 'absolute',
                        bottom: '-10px',
                        right: '-10px',
                        width: '40px',
                        height: '40px',
                        backgroundColor: '#808080',
                        color: '#fff',
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        transition: 'background-color 0.3s ease, transform 0.3s ease',
                        '&:hover': {
                            backgroundColor: '#606060',
                            transform: 'scale(1.1)',
                        },
                    }}
                >
                    {loading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : <SendIcon />}
                </IconButton>
            </Tooltip>
        </Box>
    );
};

export default TranscriptionPanel;
