import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';

const CreateArticle = () => {
  const [prompt, setPrompt] = useState("");
  const [extraPrompt, setExtraPrompt] = useState("");
  const [difficulty, setDifficulty] = useState("easy"); // Default to 'medium'
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setPrompt(event.target.value);
  };

  const handleExtraInputChange = (event) => {
    setExtraPrompt(event.target.value);
  };

  const handleDifficultyChange = (event) => {
    setDifficulty(event.target.value);
  };

  const handleSubmit = async () => {
    if (!prompt) {
      alert("Please enter a prompt!");
      return;
    }
 
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User might not be authenticated.");
      alert("You must log in to create a new speech.");
      return;
    }
    
    alert("Your speech is being created.");
    setLoading(true);

    try {
      const response = await fetch("https://koducks.com/api/initiate_speech_creation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ extraPrompt, prompt, difficulty }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Response:", response);
        console.log("Speech creation initiated:", data);
        alert(
          "Click to navigage to the Repository page to find your speech."
        );
        navigate('/repository');
        setPrompt("");
      } else {
        alert("Failed to initiate speech creation.");
      }
    } catch (error) {
      console.error("Error initiating speech creation:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setLoading(false); // Reset loading state after response is received
  }
  };

  return (
      <div className="w-full max-w-xs sm:max-w-md md:max-w-2xl lg:max-w-4xl mx-auto my-4 text-center">
        <div className="flex flex-row flex-nowrap items-center border-2 border-gray-500 rounded-full p-2 md:p-3 bg-gray-100">
          {/* Hidden select on small viewports */}
          <select
            value={difficulty}
            onChange={handleDifficultyChange}
            className="hidden lg:block bg-gray-100 border-none outline-none text-sm sm:text-base md:text-lg p-1 md:p-2 rounded-t-full md:rounded-l-full md:rounded-t-none w-full md:w-auto"
          >
            <option value="easy">easy</option>
            <option value="medium">medium</option>
            <option value="hard">hard</option>
          </select>
          {/* Input box */}
          <input
            type="text"
            placeholder="Enter a theme to create a Chinese speech"
            value={prompt}
            onChange={handleInputChange}
            className="flex-1 border-none outline-none text-sm sm:text-base md:text-lg p-2 bg-gray-100"
          />
          {/* Create button */}
          <button 
            onClick={handleSubmit} 
            className="bg-transparent border-none cursor-pointer ml-2 md:ml-4" 
            disabled={loading} // Disable button when loading is true
          >
            {loading ? (
              // Rotating circle (loading spinner)
              <svg
                className="animate-spin h-5 w-5 text-gray-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                />
              </svg>
            ) : (
              // Original play icon
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10 17L15 12L10 7V17Z" fill="gray" />
              </svg>
            )}
          </button>
        </div>
        {prompt && (
          <div className="mt-4">
            <textarea
              placeholder="Type to specify speech styles."
              value={extraPrompt}
              onChange={handleExtraInputChange}
              className="w-full border border-gray-300 rounded-md p-2 md:p-3 text-sm sm:text-base md:text-lg bg-gray-100 shadow-sm resize-none"
              rows="2"
            />
          </div>
        )}
      </div>
    );
}  

export default CreateArticle;