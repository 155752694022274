import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Repository = () => {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [expanded, setExpanded] = useState({});
  const [selectedArticleId, setSelectedArticleId] = useState(null);
  const [generatingAudio, setGeneratingAudio] = useState({});
  const [showPersonalSpeech, setShowPersonalSpeech] = useState({});

  const navigate = useNavigate();

  const handleClickForAudio = async (speech) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. User might not be authenticated.");
      alert("You must log in to generate an audio.");
      return;
    }

    // Immediately set the "creating" flag to true to reflect the UI change
    setGeneratingAudio((prev) => ({ ...prev, [speech]: true }));
    setArticles((prevArticles) =>
      prevArticles.map((article) =>
        article.id === speech ? { ...article, creating: true } : article
      )
    );

    try {
      const response = await fetch("https://koducks.com/api/create_audio", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify({ speech }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Response:", data);
        // Optionally refresh or update the articles after audio creation
      } else {
        alert("Failed to generate audio.");
      }
    } catch (error) {
      console.error("Error generating audio:", error);
      alert("An error occurred. Please try again.");
    } finally {
      setGeneratingAudio((prev) => ({ ...prev, [speech]: false }));
    }
  };

  useEffect(() => {
    const fetchSpeeches = async () => {
      const token = localStorage.getItem('token');

      const query = `
        {
          speeches {
            id
            name
            text
            audioUrl
            public
            dateCreated
            creating
          }
        }
      `;

      try {
        const response = await fetch('https://koducks.com/graphql', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            ...(token && { 'Authorization': `Bearer ${token}` }), // Add token if it exists
          },
          body: JSON.stringify({ query }),
        });

        const result = await response.json();

        if (result.data && result.data.speeches) {
          const speeches = result.data.speeches;
          setArticles(speeches);
          setFilteredArticles(speeches);
        } else {
          console.error('Failed to fetch speeches:', result.errors);
        }
      } catch (error) {
        console.error('Error fetching speeches:', error);
      }
    };

    fetchSpeeches();
  }, []);

  useEffect(() => {
    const filtered = articles.filter(
      (article) =>
        article.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredArticles(filtered);
  }, [searchQuery, articles]);

  const toggleExpand = (index) => {
    setExpanded((prevState) => ({ ...prevState, [index]: !prevState[index] }));
  };

  const truncateText = (text, length) => {
    if (text.length <= length) {
      return text;
    }
    return `${text.slice(0, length)}...`;
  };

  const handleCardClick = (id, url) => {
    localStorage.setItem('selectedSpeechUrl', url);
    localStorage.setItem('selectedSpeechId', id);

    setSelectedArticleId(id);
    console.log(`Saved speech ID: ${id} to local storage`);
    navigate('/playground');
  };

  return (
    <div className="relative w-full max-w-5xl mx-auto my-4 rounded-lg bg-gray-100">
      <div className="flex justify-between items-center bg-gray-100 rounded-lg">
        <div className="flex items-center space-x-4">
          <button
            onClick={() => setShowPersonalSpeech(!showPersonalSpeech)}
            className={`text-lg font-semibold ${
              showPersonalSpeech ? 'text-gray-800' : 'text-gray-400'
            }`}
          >
            {showPersonalSpeech ? 'Personal Speeches' : 'All Speeches'}
          </button>
        </div>
        <input
          type="text"
          placeholder="Search by title..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="text-sm w-1/3 p-2 border border-gray-300 rounded-full focus:outline-none focus:border-gray-500 placeholder-gray-500 text-gray-800"
        />
      </div>
      <div className="w-full h-[calc(100vh-260px)] overflow-y-auto p-6 sm:p-14 text-sm bg-gray-100 text-gray-800 rounded-lg mt-4">
        <div className="max-h-[70vh]">
          {filteredArticles.length > 0 ? (
            filteredArticles
              .filter(article => showPersonalSpeech ? !article.public : true) // Show personal speeches if toggle is on
              .sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)) // Sorting by dateCreated in descending order
              .map((article, index) => (
                <div
                  key={index}
                  className="p-6 border-b border-gray-200"
                >
                  <h3
                    className="text-base font-semibold text-grey-600 hover:underline cursor-pointer"
                    onClick={() => handleCardClick(article.id, article.audioUrl)}
                  >
                    {article.name}
                  </h3>
                  
                  <p className="text-gray-600 mt-2">
                    {expanded[index] ? article.text : truncateText(article.text, 200)}
                  </p>
                  <button
                    className="text-grey-500 hover:text-grey-700 mt-2 mr-4"
                    onClick={() => toggleExpand(index)}
                  >
                    {expanded[index] ? 'Show Less' : 'Read More'}
                  </button>
  
                  {/* Conditional rendering for audio */}
                  {article.audioUrl ? (
                    <audio controls className="mt-2 w-full">
                      <source src={article.audioUrl} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  ) : article.creating || generatingAudio[article.id] ? (
                    <p className="text-gray-600 italic mt-4 mb-6">Speech audio is being created...</p>
                  ) : (
                    <button
                      className="text-grey-500 hover:text-slate-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition transform hover:scale-105 shadow-md hover:shadow-lg px-3 py-1"
                      onClick={() => handleClickForAudio(article.id)}
                    >
                      Generate Audio
                    </button>
                  )}
                </div>
              ))
          ) : (
            <p className="text-gray-600">No articles found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Repository;